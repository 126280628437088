import React from "react";
import VignetoItem from "./vigneto-item/VignetoItem";
import groc1 from "../../../../src/assets/img/grocery/vig1.webp";
import groc2 from "../../../../src/assets/img/grocery/vig2.webp";
import groc3 from "../../../../src/assets/img/grocery/vig3.webp";
import groc4 from "../../../../src/assets/img/grocery/vig4.webp";

const data = [
  {
    title: "Vigneto Victorian Green 51 Pcs Dinner SetFinest Premium Porcelain | Luxury Tableware Crockery | Perfect for Gifting for ₹26,000.00 from Vigneto.",
    price: "26,000.00",
    url: groc1
  },
  {
    title: "Vigneto Spring Meadows 15 Pcs Tea Set (Vintage Collection)Finest Premium Porcelain | Luxury Tableware Crockery | Perfect for Gifting for ₹7,899.00 from Vigneto.",
    price: "7899",
    url: groc2
  },
  {
    title: "Vigneto Mughal Gold and Grey Cup and Saucer Set (6 Cups and 6 Saucers)Finest Premium Porcelain | Luxury Tableware Crockery | Perfect for Gifting for ₹3,999.00 from Vigneto.",
    price: "3,999",
    url: groc3
  },
  {
    title: "Vigneto Cr me Gold Cup and Saucer Set (6 Cups and 6 Saucers)Finest Premium Porcelain | Luxury Tableware Crockery | Perfect for Gifting for ₹3,999.00 from Vigneto.",
    price: "3,999",
    url: groc4
  }

];

const VignetoProducts = () => {
  return (
    <>
      <section className="grocerySec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="fisherman-content bgTitle mb-4">
                <h3>Vigneto Tea Set</h3>
              </div>
            </div>
            {data &&
              data.map((item, i) => {
                return <VignetoItem key={i} item={item} />;
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default VignetoProducts;
