import React from 'react';
import cosmetic1 from "../../../../src/assets/img/grocery/cosmetic1.webp";
import cosmetic2 from "../../../../src/assets/img/grocery/cosmetic2.webp";
import cosmetic3 from "../../../../src/assets/img/grocery/cosmetic3.webp";
import cosmetic4 from "../../../../src/assets/img/grocery/cosmetic4.webp";
import KitchenItem from './kitchen-item/KitchenItem';

const data = [
  {
    title: "Anko Green & Cream 12 Pieces Stoneware Glossy Dinner Set(Onesize) by Myntra",
    price: "3,699.00",
    url: cosmetic1
  },
  {
    title: "Beautiful Kitchen Ceramic Dinner Plate 11 Inch in White Colour from Living",
    price: 595.00,
    url: cosmetic2
  },
  {
    title: "@home Arias Moon Black Fantasy Dinner Set - 33 Pieces Microwave and Dishwasher",
    price: "3,118.00",
    url: cosmetic3
  },
  {
    title: "SALE Pure Home and Living White & Gold toned 20 Pieces Ceramic Dinnerware (Onesize) by",
    price: "17,999.00",
    url: cosmetic4
  }
  
]


const KitchenProducts = () => {
  return (
    <>
      <section className="grocerySec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="fisherman-content bgTitle mb-4">
                <h3>Home & Kitchen Crockery</h3>
              </div>
            </div>
            {data &&
              data.map((item, i) => {
                return <KitchenItem key={i} item={item} />;
              })}
          </div>
        </div>
      </section>

    </>
  )
}

export default KitchenProducts