import React from "react";
import FloralItem from "./floral-item/FloralItem";
import groc1 from "../../../../src/assets/img/grocery/groc1.webp";
import groc2 from "../../../../src/assets/img/grocery/groc2.jpg";
import groc3 from "../../../../src/assets/img/grocery/groc3.webp";
import groc4 from "../../../../src/assets/img/grocery/groc4.webp";
import groc5 from "../../../../src/assets/img/grocery/groc5.webp";
import groc6 from "../../../../src/assets/img/grocery/groc6.webp";
import groc7 from "../../../../src/assets/img/grocery/groc7.webp";
import groc8 from "../../../../src/assets/img/grocery/groc8.webp";

const data = [
  {
    title: "Opulent Grey 21 Pcs Dinner Set (Luxe Edition)",
    price: 26000.00,
    url: groc1
  },
  {
    title: "Teacup",
    price: 1000.00,
    url: groc2
  },
  {
    title: "Saucer",
    price: 700.00,
    url: groc3
  },
  {
    title: "Snack Set",
    price: 1500.00,
    url: groc4
  },
  {
    title: "Dessert Stands",
    price: 1200.00,
    url: groc5
  },
  {
    title: "Cutlery",
    price: 30.00,
    url: groc6
  },
  {
    title: "Mughal Gold and Grey Cup and Saucer Set (6 Cups and 6 Saucers)",
    price: 3999,
    url: groc7
  },
  {
    title: "Crème Gold Cup and Saucer Set (6 Cups and 6 Saucers)",
    price: 3999,
    url: groc8
  }

];

const FloralProducts = () => {
  return (
    <>
      <section className="grocerySec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="fisherman-content bgTitle mb-4">
                <h3>Floral Tea Set</h3>
              </div>
            </div>
            {data &&
              data.reverse().map((item, i) => {
                return <FloralItem key={i} item={item} />;
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default FloralProducts;
