import React from "react";

const FloralItem = ({ item }) => {
  return (
    <>
      <div className="col-md-3">
        <div className="featuredInfo">
          <div className="featuredFigure">
            <div className="featuredImg">
            <a href="#">
              <img
                src={item.url}
                alt={item.title}
                title={item.title}
                className="img-fluid"
              />
            </a>
            </div>
          </div>
          <div className="featuredContent">
            <h5>{item.title}</h5>
            <div class="rateDigit"><span class="currentPrice"> ₹: {item.price}.00</span></div>
          
            <div className="buyNowInfo">
              <a className="btn btn-danger buyNow" href="/">Buy Now</a>
              <a className="buyNow" href="/">Add to Cart <i className="ri-arrow-right-up-fill"></i></a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FloralItem;
